(function () {
  const NODE_LUCKY_ENV = window._runEnv;
  const ENV_MAP = {
    local: 'test03',
    test3: 'test03',
    test4: 'test04',
    pre: 'pre',
    prod: ''
  };
  const env = ENV_MAP[NODE_LUCKY_ENV];


  // 通用域名逻辑
  window.imageDomain = `https://img${env}.luckincoffeecdn.com/`;
})();
