import http from '../utils/http'

// Buffer.from('resour').toString('base64')
// 登陆
export const login = (data) => {
  return http.post('/login', data)
}

// 访问记住密码解码
export const isRemember = () => {
  return http.post('/isRemember', {})
}

// 获取企业信息和权限
export const getUserInfo = () => {

}

// 获取企业基本信息接口
export const getCompanyInfo = (data) => {
  return http.get('/resource/e/company/baseInfo', data)
}

export function logout() {
  return http({
    url: '/user/logout',
    method: 'post'
  })
}
