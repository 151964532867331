import Vue from 'vue';
const cache = {
  addValInt: 0,
  setTimeoutList: {},
  setCache: {},
  appFunction: {}, // 动态插入的app事件
}

function getApp(newVue) {
  const app = {
    cache,
    newVue,
    appKey: cache.appKey, // app标记值，如果出现不一致，则重新获取app
    get only() { // 获取唯一值
      if (cache.addValInt > 9999) cache.addValInt = 0;
      return Date.now().toString().slice(3) + (cache.addValInt++).toString().padStart(4, '0');
    },
    getOnly(prefix = '') { // 获取唯一标记值
      return prefix + this.only;
    },
    each(data, backfun) {
      for (const x in data) {
        const t = data[x];
        if (backfun(t, x) === false) break;
      }
      return data;
    },
    isEqual(obj1, obj2) { // 判断两个obj类型中的值是否都相等
      let is = true;
      if (typeof obj1 !== typeof obj2) {
        return false;
      } else if (typeof obj1 !== 'object') {
        return (obj1 === obj2);
      }

      app.each(app.jsonPush(obj1, obj2), (t, key) => {
        t = obj1[key];
        if (typeof t === 'object') {
          if (!obj2[key] || typeof obj2 !== 'object') return (is = false);
          else if (app.isEqual(t, obj2[key]) === false) return (is = false);
        } else if (t !== obj2[key]) return (is = false);
      });
      return is;
    },
    setCache(name, data, obj) { // 保存缓存数据
      if (cache.setCache[name]) delete cache.setCache[name];
      cache.setCache[name] = {
        data,
        obj,
      };
    },
    getCache(name, obj) { // 读取缓存
      if (cache.setCache[name] && this.isEqual(obj, cache.setCache[name].obj)) {
        return cache.setCache[name].data;
      }
      return false;
    },
    jsonPush(j1, j2, isFirst = true) {
      if (isFirst)j1 = this.jsonPush({}, j1, false);

      for (const x in j2) {
        j1[x] = j2[x];
      }
      return j1;
    },
    pxToInt(px) { // px字符串转int
      if (typeof px === 'string') {
        const int = parseInt(px.replace('px', ''));
        if (typeof int === 'number') return int;
        else return 0;
      } else return 0;
    },
    logout() { // 退出登入
      localStorage.clear();
      app.clearCookie();
      window.location.href = window.location.origin + "/login";
      return app;
    },
    clearCookie() { // 清除所有cookie     
      var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
      if (keys) {
          for (var i = keys.length; i--;) {
              document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();//清除当前域名下的
              document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();//清除当前域名下的
          }
      }
      console.log('已清除cookie');
    },
    addFunction(name, backfun, cover = true) { // 插入公共事件
      if (cover === false && cache.appFunction[name]) return app;// 不覆盖原有函数
      cache.appFunction[name] = backfun;
      cache.appKey = this.getOnly('key');
      return app;
    },
    isHide(element) { // dom是否隐藏
      return (element.offsetParent === null);
    },
    setTimeout(back, name, delay = 150,) { // 延迟函数,同一时间段只加入一个setTimeout避免卡顿（有空再优化吧）
      // if (!name) {
      //   if (newVue)name = newVue.name;
      //   else return console.log('setTimeout请出入第二个参数name标记');
      // }
      // let list = cache[delay];
      // if (!list) {
      //   cache[delay] = list = { startTime: Date.now() };

      //   setTimeout(() => {
      //     this.each(list, b => {
      //       b();
      //     })
      //   }, delay)
      // }
      // list[name] = back;
      clearTimeout(this.clearTime);
      this.clearTime = setTimeout(back, delay)
    },
    goRisize() { // 触发窗口变化事件
      var event = document.createEvent("HTMLEvents");
      event.initEvent("resize", true, true);
      window.dispatchEvent(event);
    },
    get isMac() { // 判断是否mac
      return /macintosh|mac os x/i.test(navigator.userAgent);
    },
    scroll() { // 模拟滚动

    },
    iframeFirst(iframeWindow) { // iframe初始化事件
      if (iframeWindow.isIframeFirst) return;
      iframeWindow.isIframeFirst = true;

      // 插入iconfont到子系统
      const cssDom = document.querySelector('#iconfont-css');
      const linkDom = document.createElement('link');
      linkDom.rel = 'stylesheet';
      linkDom.href = cssDom.href;
      iframeWindow.document.querySelector('head').appendChild(linkDom);

      // 冒泡点击事件至父框架
      iframeWindow.document.addEventListener("click", (e) => {
        window['layout-scroll'].click(e);
      });
    }
  }

  app.each(cache.appFunction, (t, name) => { app[name] = t });
  return app;
}

getApp.vueSetApp = function(Vue) { // 将app插入vue原型中
  if (!Vue.$app) {
    window.$app = getApp;
    Object.defineProperties(Vue.prototype, { // 绑定全局公共函数$app
      $app: {
        get() {
          if (this.$_app && this.$_app.appKey === cache.appKey) return this.$_app;// 已经存在最新app版本，不需要重新获取
          else this.$_app = getApp(this);// 获取app
          return this.$_app;
        }
      }
    });
  }
}
getApp.vueSetApp(Vue);

export default getApp;
