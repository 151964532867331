import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/HomePage';

Vue.use(VueRouter);
const moduleName = 'HomePage';
const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/welcome',
    component: Home,
    component: () => import(/* webpackChunkName: "default/[request]" */ `../views/${moduleName}`),
    children: [{
      path: 'welcome',
      component: () => import(`../views/Welcome`),
      name: 'welcome',
      meta: {
        title: '首页',
      }
    }, {
      path: 'employee',
      component: () => import('../views/Employee'),
      name: 'employee',
      meta: {
        title: '员工管理'
      }
    }, {
      path: 'order',
      component: () => import('../views/Order'),
      name: 'order',
      meta: {
        title: '订单查询'
      }
    }, {
      path: 'account',
      component: () => import('../views/Account'),
      name: 'account',
      meta: {
        title: '账户管理'
      },
      children: [{
        path: 'prepayment',
        component: () => import('../views/Account/Prepayment'),
        name: 'prepayment',
        meta: {
          title: '预付费账户'
        }
      },
      {
        path: 'postpayment',
        component: () => import('../views/Account/Postpayment'),
        name: 'postpayment',
        meta: {
          title: '后付费账户'
        },
        children: [{
          path: '/',
          component: () => import('../views/Account/Postpayment/postpayment'),
          // name: 'postpayment',
          // meta: {
          //   title: '后付费账户'
          // },
        }, {
          path: 'postBill',
          component: () => import('../views/Account/Postpayment/postbill'),
          name: 'postBill',
          meta: {
            title: '查看账单'
          },
        }]
      },
      {
        path: 'recharge',
        component: () => import('../views/Account/Recharge'),
        name: 'recharge',
        meta: {
          title: '还款'
        }
      },
      ]
    }, {
      path: 'invoice',
      component: () => import('../views/Invoice'),
      name: 'invoice',
      meta: {
        title: '发票管理'
      }
    }, {
      path: 'deduct',
      component: () => import('../views/Deduct'),
      name: 'deduct',
      meta: {
        title: '提成管理（旧）'
      }
    },{
      path: 'deduct2',
      component: () => import('../views/Deduct2'),
      name: 'deduct2',
      meta: {
        title: '提成管理'
      }
    }, {
      path: 'system',
      component: () => import('../views/System'),
      name: 'system',
      meta: {
        title: '系统设置'
      }
    }]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login'),
    meta: {
      title: '登陆'
    }
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import('../views/Apply'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('../views/Forget'),
    meta: {
      title: '忘记密码'
    }
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('../views/ChangePassword'),
    meta: {
      title: '修改密码'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/Privacy'),
    meta: {
      title: '隐私政策',
    },
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
