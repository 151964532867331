import VueI18n from 'vue-i18n';

export default function() {
  try {
    const messages = {
      [localStorage.lang]: JSON.parse(localStorage.locale)
    }

    return new VueI18n({
      locale: localStorage.lang,
      messages
    })
  } catch (e) {
    console.error('可能国际化接口无法访问，localStorage.locale中无国际化信息', e);
    return new VueI18n({
      locale: 'cn-zh',
      messages: {}
    });
  }
}
