const filters = {
  // 返回字符串第一个字母的大写
  firstChar(val) {
    return val[0].toUpperCase();
  },
  // lowercase
  lowerCase(val) {
    return val.toLowerCase();
  },

  // 充值单/账单 格式化，2是充值单
  transformInvoiceObject(val) {
    return +val === 2 ? '充值单' : '账单';
  },

  transformUserType(val) {
    switch (val) {
      case 0: return '超级管理员';
      case 1: return '高级管理员';
      case 2: return '普通管理员';
    }
  }
}

export default {
  install(Vue) {
    for (const [name, fun] of Object.entries(filters))
      Vue.filter(name, fun);
  }
}