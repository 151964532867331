import './public-path';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import filters from './filters';
import './domain';

// 引入UI组件库
import './ui';
import './styles/index.less';

Vue.config.productionTip = false;
Vue.use(filters);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
