<template>
  <lk-container
    class="homePage"
  >
    <lk-aside
      width="200px"
      style="background-color: #282C4C; display: flex; flex-direction: column;"
    >
      <div class="top-header">
        <div class="avatar">
          <img src="../../assets/avatar.png">
        </div>
        <p class="corp-title">
          luckin coffee 企业平台
        </p>
        <p class="welcome">
          <span>欢迎 {{ $store.state.user.info.empName }}</span>
          <lk-button
            type="text"
            @click="logout"
          >
            退出
          </lk-button>
        </p>
      </div>
      <!-- <lk-menu
        :default-active="active"
        :router="true"
        background-color="#282C4C"
        text-color="#fff"
        active-text-color="#fff"
        class="menu"
      >
        <lk-menu-item index="/welcome">
          <i class="lk-icon-s-home" />
          <span slot="title">首页</span>
        </lk-menu-item>
        <lk-menu-item index="/employee">
          <i class="lk-icon-s-custom" />
          <span slot="title">员工管理</span>
        </lk-menu-item>
        <lk-menu-item
          v-if="$store.state.user.info.userRight !== 4"
          index="/order"
        >
          <i class="lk-icon-s-order" />
          <span slot="title">订单查询</span>
        </lk-menu-item>

        <lk-submenu
          v-if="$store.state.user.info.userRight !== 4"
          index="4"
        >
          <template slot="title">
            <i class="lk-icon-s-finance" />
            账户管理
          </template>
          <lk-menu-item
            v-if="$store.state.user.info.userRight !== 2"
            index="/account/prepayment"
          >
            <span slot="title">预付费账户</span>
          </lk-menu-item>
          <lk-menu-item
            v-if="$store.state.user.info.userRight !== 1"
            index="/account/postpayment/"
          >
            <span slot="title">后付费账户</span>
          </lk-menu-item>
        </lk-submenu>

        <lk-menu-item
          v-if="$store.state.user.info.userRight !== 4"
          index="/invoice"
        >
          <i class="lk-icon-s-ticket" />
          <span slot="title">发票管理</span>
        </lk-menu-item>

        <lk-menu-item
          index="/deduct"
        >
          <i class="lk-icon-s-ticket" />
          <span slot="title">提成管理</span>
        </lk-menu-item>

        <lk-menu-item index="/system">
          <i class="lk-icon-s-tools" />
          <span slot="title">系统设置</span>
        </lk-menu-item>
      </lk-menu> -->

      <lk-menu
        :default-active="active"
        :router="true"
        background-color="#282C4C"
        text-color="#fff"
        active-text-color="#fff"
        class="menu"
      >
        <template v-for="m in menu">
          <template v-if="m.children">
            <lk-submenu
              v-if="m.authScope.includes($store.state.user.info.authScope)"
              :key="m.url + m.icon"
              :index="m.url"
            >
              <template slot="title">
                <i :class="m.icon" />
                {{ m.name }}
              </template>
              <template v-for="c in m.children">
                <lk-menu-item
                  v-if="c.accountBuildStatus.includes($store.state.user.info.accountBuildStatus)"
                  :key="c.url + c.icon"
                  :index="c.url"
                >
                  <span slot="title">{{ c.name }}</span>
                </lk-menu-item>
              </template>
            </lk-submenu>
          </template>
          <template v-else>
            <lk-menu-item
              v-if="m.authScope.includes($store.state.user.info.authScope) && !(m.isShopPayFlag && $store.state.user.info.shopPayFlag ) "
              :key="m.url + m.icon"
              :index="m.url || ''"
            >
              <i :class="m.icon" />
              <span slot="title">{{ m.name }}</span>
            </lk-menu-item>
          </template>
        </template>
      </lk-menu>
      <div class="helpbook">
        <lk-popover
          v-model="showHandleBooks"
          placement="top"
          trigger="manual"
        >
          <div
            style="text-align: right;"
            @click="showHandleBooks = false"
          >
            <i class="lk-icon-close" />
          </div>
          <div
            v-for="(val, key) in userHandleBooks"
            :key="key"
            class="box-card"
          >
            <lk-link
              @click="handleHelp(val)"
            >
              {{ key }} <i class="lk-icon-download" />
            </lk-link>
          </div>
          <lk-link
            slot="reference"
            @click="handleHelp()"
          >
            <i class="lk-icon-notebook-1" />使用手册
          </lk-link>
        </lk-popover>
        <!-- <lk-link @click="handleHelp">
          <i class="lk-icon-notebook-1" />使用手册
        </lk-link> -->
      </div>
    </lk-aside>

    <lk-container class="main-container">
      <lk-header class="breadcrumb">
        <p>
          <i class="lk-icon-s-home" />
          <span
            v-for="(e, i) in meta"
            :key="i"
            :class="(e.path === active || e.path === '/account' || active === '/account/postpayment/')? 'links active-link':'links'"
            @click="gotoNav(e)"
          >{{ i !== 0 ? '>' : '' }} {{ e.meta.title === '还款' ? $route.query.page === 'postpayment' ? '还款': '充值' : e.meta.title }}</span>
        </p>
      </lk-header>

      <lk-main>
        <router-view />
      </lk-main>
    </lk-container>
  </lk-container>
</template>

<script>
import http from '../../utils/http'
import { getUserInfo } from '../../service/user'
import getApp from '../../utils/app';

let staticDomain = ''
switch (window._runEnv) {
  case 'test03':
    staticDomain = 'http://statictest03.luckincoffeecdn.com';
    break;
  case 'test04':
    staticDomain = 'http://statictest04.luckincoffeecdn.com';
    break;
  case 'pre':
    staticDomain = 'http://staticpre.luckincoffeecdn.com';
    break;
  case 'prod':
    staticDomain = 'https://static.luckincoffeecdn.com';
    break;
  default:
    staticDomain = 'https://static.luckincoffeecdn.com';
    break;
}
const menu = [
  {
    name: '首页',
    icon: 'lk-icon-s-home',
    url: '/welcome',
    authScope: [1, 2, 3]
  },
  {
    name: '员工管理',
    icon: 'lk-icon-s-custom',
    url: '/employee',
    authScope: [1, 2]
  },
  {
    name: '订单查询',
    icon: 'lk-icon-s-order',
    url: '/order',
    authScope: [1, 2]
  },
  {
    name: '账户管理',
    icon: 'lk-icon-s-finance',
    url: '4',
    authScope: [1, 2],
    children: [
      {
        name: '预付费账户',
        url: '/account/prepayment',
        authScope: [1, 2],
        accountBuildStatus: [1, 3]
      },
      {
        name: '后付费账户',
        url: '/account/postpayment',
        authScope: [1, 2],
        accountBuildStatus: [2, 3]
      }
    ]
  },
  {
    name: '发票管理',
    icon: 'lk-icon-s-ticket',
    url: '/invoice',
    authScope: [1, 2],
    isShopPayFlag: true,  // 针对shopPayFlag配置项（目前仅有铁科院）需要隐藏目录
  },
  // {
  //   name: '提成管理（旧）',
  //   icon: 'lk-icon-s-ticket',
  //   url: '/deduct',
  //   authScope: [2, 3]
  // },
  {
    name: '提成管理',
    icon: 'lk-icon-s-ticket',
    url: '/deduct2',
    authScope: [2, 3]
  },
  {
    name: '系统设置',
    icon: 'lk-icon-s-tools',
    url: '/system',
    authScope: [1, 2, 3]
  }
]

const $app = getApp();
export default {
  name: "Welcome",
  data() {
    return {
      info: {},
      meta: '',
      active: '/welcome',
      menu,
      showHandleBooks: false,
    }
  },
  computed: {
    userHandleBooks() {
      const pdfs = {
        0: `企业平台客户端使用手册-合作商.pdf`,
        1: `企业平台客户端使用手册-后付费.pdf`,
        2: `企业平台客户端使用手册-预付费.pdf`
      }
      const { authScope, accountBuildStatus } = this.$store.state.user.info;
      const prefix = `${staticDomain}/web/file/`;
      // -1-没有系统权限 1-优惠账户 3-合作商账户 2-优惠账户和合作商账户
      if ([1, 2].includes(authScope) && accountBuildStatus !== 0) {
        const books = {};
        if (2 === authScope) {
          books[pdfs[0]] = `${prefix}${pdfs[0]}`;
        }
        if (1 === accountBuildStatus) {
          books[pdfs[2]] = `${prefix}${pdfs[2]}`;
        } else if (2 === accountBuildStatus) {
          books[pdfs[1]] = `${prefix}${pdfs[1]}`;
        } else if (3 === accountBuildStatus) {
          books[pdfs[1]] = `${prefix}${pdfs[1]}`;
          books[pdfs[2]] = `${prefix}${pdfs[2]}`;
        }
        return books;
      }
      if (authScope === -1) {
        return ``;
      }
      return `${prefix}${pdfs[0]}`;
    }
  },
  watch: {
    '$route': 'getMate'
  },
  async created() {
    await this.$store.dispatch("user/getInfo");
    await this.$store.dispatch("user/getCompanyInfo");
    this.getMate()
    // const { cooperatingStateFlag } = this.$store.state.user.info;
    // // 如登陆用户当前身份包含合作商账户，则每次均在企业平台首页弹出相关内容弹窗
    // if(cooperatingStateFlag){
    //   this.$alert('尊敬的合作商您好：从9月1日起，可在【提成管理】菜单中下载（2022年8月起）对账单，感谢您的支持与配合！','提示', {dangerouslyUseHTMLString: true});
    // }
  },
  methods: {
    // meta信息
    async getMate() {
      const metas = this.$route.matched.filter(e => {
        if (e.meta && e.meta.title) {
          return `<span>${e.meta.title}</span>`
        }
      })
      const data = metas.map(e => `<span>/ ${e.meta.title}</span>`)
      console.log(data.join('/ '))
      this.meta = metas
      console.log(this.meta);
      this.active = this.$route.matched[this.$route.matched.length - 1].path
    },
    gotoNav(e) {
      if (this.active !== e.path && e.path !== '/account') {
        this.$router.push(e.path)
      }
    },
    logout() {
      this.$confirm('确定退出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        $app.clearCookie()
        $app.logout()
      });
    },
    handleHelp(url) {
      if (url === undefined) {
        if (typeof this.userHandleBooks === 'string') {
          window.open(this.userHandleBooks);
        } else {
          this.showHandleBooks = true;
        }
        return;
      }
      window.open(url ?? this.userHandleBooks);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/theme.less";

.font-family {
  font-family: 'PingFang SC', Avenir, Helvetica, "Microsoft Yahei", Arial, "sans-serif";
}

.homePage {
  width: 100%;
  height: 100%;
  .font-family;

  .top-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    margin-bottom: 20px;
    color: #fff;
    .avatar {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 30px 0 20px;
      width: 100%;
    }
    .corp-title {
      width: 100%;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
    }
    p:last-child {
      display: flex;
      justify-content: space-around;
      width: 100%;
      align-items: center;
      font-size: 12px;
    }
  }
  .el-header {
    background-color: #b3c0d1;
    color: #333;
    line-height: 60px;
    .links {
      cursor: pointer;
      color: @brandMainColor;
    }
    .links:hover {
      color: #758bce;
    }
    .active-link,
    .active-link:hover {
      color: @characterNormalColor;
      cursor: text;
    }
  }

  .menu {
    border-right: none;
    flex: 1;
    & > .el-menu-item,
    & > .el-submenu /deep/ .el-submenu__title {
      @h: 50px;
      height: @h;
      line-height: @h;
      i:first-child {
        margin-right: 10px;
        font-size: 18px;
        line-height: @h;
        vertical-align: middle;
      }
    }
    & > .el-submenu /deep/ .el-submenu__title {
      i:first-child {
        margin-right: 6px;
      }
    }
    .el-menu-item.is-active {
      background-color: #7CA7D2 !important;
    }
  }

  .helpbook {
    // position: absolute;
    // bottom: 20px;
    padding-bottom: 20px;
    width: 200px;
    font-size: 20px;
    text-align: center;
    i {
      margin-right: 10px;
      font-size: 20px;
    }
    .el-link {
      font-size: 16px;
      color: #fff;
      &.is-underline:after {
        border-color: #fff;
      }
    }
  }

  .main-container {
    background-color: #f5f5f5;
    .breadcrumb {
      background-color: #f5f5f5;
      margin-top: 10px;
      padding: 0 10px 0;
      height: auto;
      & > p {
        background-color: #fff;
        height: 55px;
        padding: 0 20px;
        line-height: 55px;
        border-radius: 6px;
      }
    }
    .el-main {
      background-color: #f5f5f5;
      padding: 5px 10px 60px;
    }
  }
}
</style>
