import Vue from 'vue';
import {
  LkPagination,
  LkDialog,
  LkAutocomplete,
  LkDropdown,
  LkDropdownMenu,
  LkDropdownItem,
  LkMenu,
  LkSubmenu,
  LkMenuItem,
  LkMenuItemGroup,
  LkInput,
  LkInputNumber,
  LkRadio,
  LkRadioGroup,
  LkRadioButton,
  LkCheckbox,
  LkCheckboxButton,
  LkCheckboxGroup,
  LkSwitch,
  LkSelect,
  LkOption,
  LkOptionGroup,
  LkButton,
  LkButtonGroup,
  LkTable,
  LkTableColumn,
  LkDatePicker,
  LkTimeSelect,
  LkTimePicker,
  LkPopover,
  LkTooltip,
  LkBreadcrumb,
  LkBreadcrumbItem,
  LkForm,
  LkFormItem,
  LkTabs,
  LkTabPane,
  LkTag,
  LkTree,
  // LkAlert,
  LkSlider,
  LkIcon,
  LkRow,
  LkCol,
  LkUpload,
  LkProgress,
  LkSpinner,
  LkBadge,
  LkCard,
  LkRate,
  LkSteps,
  LkStep,
  LkCarousel,
  LkCarouselItem,
  LkCollapse,
  LkCollapseItem,
  LkCascader,
  LkColorPicker,
  LkTransfer,
  LkContainer,
  LkHeader,
  LkAside,
  LkMain,
  LkFooter,
  LkTimeline,
  LkTimelineItem,
  LkLink,
  LkDivider,
  LkImage,
  LkCalendar,
  LkBacktop,
  LkPageHeader,
  LkCascaderPanel,
  LkLoading,
  LkMessageBox,
  LkMessage,
  LkNotification,
  LkSearchForm,
  LkButtonTimer
} from '@lucky/lucky-ui';

Vue.use(LkPagination);
Vue.use(LkDialog);
Vue.use(LkAutocomplete);
Vue.use(LkDropdown);
Vue.use(LkDropdownMenu);
Vue.use(LkDropdownItem);
Vue.use(LkMenu);
Vue.use(LkSubmenu);
Vue.use(LkMenuItem);
Vue.use(LkMenuItemGroup);
Vue.use(LkInput);
Vue.use(LkInputNumber);
Vue.use(LkRadio);
Vue.use(LkRadioGroup);
Vue.use(LkRadioButton);
Vue.use(LkCheckbox);
Vue.use(LkCheckboxButton);
Vue.use(LkCheckboxGroup);
Vue.use(LkSwitch);
Vue.use(LkSelect);
Vue.use(LkOption);
Vue.use(LkOptionGroup);
Vue.use(LkButton);
Vue.use(LkButtonGroup);
Vue.use(LkTable);
Vue.use(LkTableColumn);
Vue.use(LkDatePicker);
Vue.use(LkTimeSelect);
Vue.use(LkTimePicker);
Vue.use(LkPopover);
Vue.use(LkTooltip);
Vue.use(LkBreadcrumb);
Vue.use(LkBreadcrumbItem);
Vue.use(LkForm);
Vue.use(LkFormItem);
Vue.use(LkTabs);
Vue.use(LkTabPane);
Vue.use(LkTag);
Vue.use(LkTree);
// Vue.use(LkAlert);
Vue.use(LkSlider);
Vue.use(LkIcon);
Vue.use(LkRow);
Vue.use(LkCol);
Vue.use(LkUpload);
Vue.use(LkProgress);
Vue.use(LkSpinner);
Vue.use(LkBadge);
Vue.use(LkCard);
Vue.use(LkRate);
Vue.use(LkSteps);
Vue.use(LkStep);
Vue.use(LkCarousel);
Vue.use(LkCarouselItem);
Vue.use(LkCollapse);
Vue.use(LkCollapseItem);
Vue.use(LkCascader);
Vue.use(LkColorPicker);
Vue.use(LkTransfer);
Vue.use(LkContainer);
Vue.use(LkHeader);
Vue.use(LkAside);
Vue.use(LkMain);
Vue.use(LkFooter);
Vue.use(LkTimeline);
Vue.use(LkTimelineItem);
Vue.use(LkLink);
Vue.use(LkDivider);
Vue.use(LkImage);
Vue.use(LkCalendar);
Vue.use(LkBacktop);
Vue.use(LkPageHeader);
Vue.use(LkCascaderPanel);

Vue.use(LkLoading);
Vue.use(LkMessageBox);
Vue.use(LkMessage);
Vue.use(LkNotification);
Vue.use(LkSearchForm);
Vue.use(LkButtonTimer);
