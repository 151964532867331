import Vue from 'vue';
import axios from 'axios';
import Cookies from 'js-cookie'
import I18n from './I18n'
import getApp from './app'
import { reviseUrl } from './index'

const $app = getApp();

// 计算登出的次数
let logoutCounter = 0;
$app.addFunction('getApi', function({ headers, defaultOptions, axios, Cookies }) {
  // 设置响应错误码对应处理
  const axiosInstance = axios.create();
  axiosInstance.interceptors.response.use(res => {
    return res;
  }, error => {
    console.info("axios interceptors error", error);
    const { status, data } = error.response;
    const MessageBox=Vue.prototype.$msgbox;
    switch (status) {
      case 302:
        document.domain = reviseUrl('luckincoffee.com');
        window.top.location.reload();
        break;
      case 401:
        MessageBox.confirm('没有该页面权限！', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false,
          type: 'warning'
        });
        break;
      case 403:
        if (logoutCounter === 0) {
          logoutCounter++;
          MessageBox.confirm('登录信息已过期，请重新登录！', '提示', {
            confirmButtonText: '确定',
            showCancelButton: false,
            showClose: false,
            closeOnPressEscape: false,
            type: 'warning'
          }).then(() => {
            $app.logout();
          });
        }
        break;
      case 404:
        Vue.prototype.$message.error('接口地址 404， 请确认接口已经发布了!');
        break;
      case 500:
        Vue.prototype.$message.error("服务器异常");
        break;
      case 502:
        MessageBox.confirm('系统正在部署，请稍候。', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false,
          type: 'warning'
        }).then(() => {
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.href = window.location.origin + '/api/logout';
        }).catch(() => {
        });
        break;
      case 406:
      // 如果是406，说明是业务异常，将提示语句显示出来
      // eslint-disable-next-line no-case-declarations
        const errorMessage = data.msg || data.message || '服务器异常';
        Vue.prototype.$message.error(errorMessage);
        break;
      default:
        break;
    }
    return Promise.reject(error);
  });

  // var loginoutControl = 0;
  const Api = {
    request({ url = '', method = 'GET', data = {}, options = {}}) {
      const setOptions = {
        ...defaultOptions,
        ...options
      };
      const realUrl = setOptions.isApiUrl ? `${setOptions.baseUrl}${url}` : `${url}`;
      console.log("===http, realUrl=", realUrl);
      let language = localStorage.getItem("lang") || 'en';
      if (language === 'en') {
        language = "en_US";
      }
      const nodeEnv = window._runEnv.toUpperCase();
      const projectName = process.env.PROJECT_NAME.toUpperCase();
      console.log('nodeEnv: ', nodeEnv);
      const defaultHeaders = {
          'transformJSON': setOptions.jsonData ? Date.now : '',
          'x-csrf-token': Cookies.get(`LK_${nodeEnv}_${projectName}_csrfToken`),
          'Accept-Language': language,
          'x-jwt-token': Cookies.get(`LK_${nodeEnv}_${projectName}_jwtToken`),
      }
      const requestParams = {
        url: realUrl,
        method: method.toUpperCase(),
        headers: {
          ...defaultHeaders,
          ...headers,
        },
      };

      if (method.toUpperCase() === 'GET') {
        requestParams.params = data;
      } else {
        requestParams.data = data;
      }
      return new Promise((resolve, reject) => {
        if (options.isCache) {
          const cacheData = $app.getCache(realUrl, data);
          if (cacheData) return resolve(cacheData);// 读取到缓存数据（不在执行请求）
        }

        axiosInstance.request(requestParams).then(result => {
          resolve(result.data);
          if (options.isCache) {
            $app.setCache(realUrl, result.data, data);// 缓存数据
          }
        }).catch(err => {
          reject(err);
        });
      });
    },
    get(url = '', data = {}, options = {}) {
      return this.request({
        url,
        method: 'GET',
        data,
        options
      });
    },
    post(url = '', data = {}, options = {}) {
      return this.request({
        url,
        method: 'POST',
        data,
        options
      });
    },
    delete(url = '', data = {}, options = {}) {
      return this.request({
        url,
        method: 'DELETE',
        data,
        options
      })
    }
  };
  return Api;
}, false);

export default getApp().getApi({
  defaultOptions: {
    baseUrl: '/api',
    showErrorMessage: true,
    isApiUrl: true, // 是否是api那边接口，login相关的是统一认证
  },
  axios, Cookies
});
