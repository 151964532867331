import { logout, getUserInfo } from '../../service/user'
import { removeToken } from '../../utils/auth'
import getApp from '../../utils/app'
import Cookies from 'js-cookie'
import { getCompanyInfo } from '../../service/user'
const $app=getApp();
const state = {
  info: {
    uid: '',
    accountBuildStatus: 3,
    authScope: 1,
    defaultPassword: 1,
    empId: 263,
    empName: '金林',
    entAccountStatus: 1,
    entCoptState: 1,
    enterpriseNo: 5312,
    mobile: '18911976770',
    nature: 0,
    payAccountStatus: 0,
    paySource: 2,
    paySourceHis: 2,
    payType: 1,
    payTypeLast: 1,
  },
  companyInfo: {},
  token: Cookies.get('csrfToken'),
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.employeeName = name
  },
  SET_INFO: (state, info) => {
    state.info = info;
  },
  SET_COMPANY_INFO: (state, info) => {
    state.companyInfo = info
  }
}

const actions = {

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      try {
        const data = Cookies.get('info')
        if (!data) {
          reject('Verification failed, please Login again.')
        }
        const res = JSON.parse(Buffer.from(data, 'base64').toString('utf-8'))
        console.log(res);
        commit('SET_INFO', res)
        resolve(data)
      } catch (error) {
        reject(error);
        $app.logout();
      }
    })
  },

  getCompanyInfo({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = Cookies.get('info')
        if (!data) {
          reject('Verification failed, please Login again.')
        }
        const companyId = JSON.parse(Buffer.from(data, 'base64').toString('utf-8')).enterpriseNo
        console.log(res);
        const res = await getCompanyInfo({ companyId })
        if (res.success) {
          commit('SET_COMPANY_INFO', res.data)
          resolve(res.data)
        } else {
          reject(res.msg);
        }
      } catch (error) {
        reject(error);
        $app.logout();
      }
    })
  }, 

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      $app.logout();
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
