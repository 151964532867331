/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/\+/g, ' ') +
    '"}'
  )
}
/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * 精度格式化，根据传入的数字转成带精度显示的String，默认两位
 * @param number Number 原数字值
 * @param precision Number 精度，默认2
 * @returns string
 */
export function formatPrecision(number, precision = 2) {
  if (isNaN(number)) {
    number = 0;
  }
  const roundNum = Math.round(number * 100) / 100;
  let roundStr = roundNum.toString();
  let dotIndex = roundStr.indexOf(".");
  if (dotIndex < 0) {
    dotIndex = roundStr.length;
    roundStr += ".";
  }
  while (roundStr.length <= dotIndex + precision) {
    roundStr += "0";
  }
  return roundStr;
}

// 加
export function floatAdd(arg1, arg2) {
  let r1, r2;
  try { r1 = arg1.toString().split(".")[1].length } catch { r1 = 0 }
  try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
  const m = Math.pow(10, Math.max(r1, r2));
  return (arg1 * m + arg2 * m) / m;
}

// 减
export function floatSub(arg1, arg2) {
  let r1, r2;
  try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
  const m = Math.pow(10, Math.max(r1, r2));
  // 动态控制精度长度
  const n = (r1 >= r2) ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

// 乘
export function floatMul(arg1, arg2) {
  let m = 0;
  const s1 = arg1.toString();
  const s2 = arg2.toString();
  try { m += s1.split(".")[1].length } catch { console.log(m) }
  try { m += s2.split(".")[1].length } catch { console.log(m) }
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}

// 除
export function floatDiv(arg1, arg2) {
  let t1 = 0;
  let t2 = 0;
  try { t1 = arg1.toString().split(".")[1].length } catch { console.log(t1) }
  try { t2 = arg2.toString().split(".")[1].length } catch { console.log(t2) }
  const r1 = Number(arg1.toString().replace(".", ""));
  const r2 = Number(arg2.toString().replace(".", ""));
  return (r1 / r2) * Math.pow(10, t2 - t1);
}

/** 多域名 */
export function reviseUrl(url) {
  if (/^localhost|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/.test(window.location.hostname)) {
    return url;
  }
  const suffixDomainArr = window.location.hostname.match(/(luckincoffee|lkcoffee)(\.[a-zA-Z0-9]+)+$/);
  const suffixDomain = suffixDomainArr ? suffixDomainArr[0] : '';
  return url.replace(/(luckincoffee|lkcoffee)(\.[a-zA-Z0-9]+)+/g, suffixDomain);
}